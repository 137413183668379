<template>
  <van-form validate-trigger="onChange" @submit="submit">
    <div v-for="(item, index) in fieldData" :key="index">
      <van-cell border is-link :clickable="false"
        v-if="item.type === 'text'" center size="small">
        <template #right-icon >
          <van-field
            :name="item.name"
            :disabled="item.disabled"
            :label="item.title"
            :required="item.required"
            :placeholder="`请输入${item.title}`"
            v-model="modal[item.name]"
            :rules="item.rule"
            v-on:blur="emitChange()"
            :border="false"
          />
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-cell v-if="item.type === 'textarea'">
        <template #title>
          <span style="margin-left:.3rem;color:#646566">{{item.title}}</span>
        </template>
        <template #label>
          <van-field
            :required="item.required"
            :name="item.name"
            :disabled="item.disabled"
            :show-word-limit="item.show_word_limit"
            :maxlength="item.max_length"
            type="textarea"
            :placeholder="`请输入${item.title}`"
            v-model="modal[item.name]"
            :rows="item.rows"
            :rules="item.rule"
            v-on:blur="emitChange()"
            style="background:#efefef;
                   width:95%;
                   border-radius:10px;
                   margin-left:.3rem;"
          />
        </template>
      </van-cell>
      <van-cell is-link border size="small"
        v-if="item.type === 'area'" center :clickable="false">
        <template #title>
          <span style="color:red;margin:0 2px 0 8px">*</span>
          <span style="color:#646566">{{item.title}}</span>
        </template>
        <template #right-icon>
          <van-field
            
            type="text"
            placeholder="请选择省市区"
            v-model="modal[item.name]"
            :border="false"
            readonly
            @click="showLocationSelection = true"
            style="width:60%"
            :rules="[{ required: true, message: '' }]"
          />
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-cell is-link center border 
        v-if="item.type === 'password' && !item.inline">
        <template #right-icon>
          <van-field
            :label="item.title"
            :required="item.required"
            :name="item.name"
            :placeholder="`请输入${item.title}`"
            v-model="modal[item.name]"
            :disabled="item.disabled"
            :rules="item.rule"
            type="password"
            :border="false"
            v-on:blur="emitChange()"
          />
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-field
        :name="item.name"
        :label="item.title"
        :required="item.required"
        :disabled="item.disabled"
        v-if="item.type === 'password' && item.inline"
        :placeholder="`请输入${item.title}`"
        v-model="inlineModal[item.name].value"
        :rules="item.rule"
        type="password"
        v-on:blur="emitChange()"
      />
      <van-cell border center :clickable="false" 
        v-if="item.type === 'file' && (item.compressed == null || item.compressed)" is-link>
        <template #title>
          <div style="padding-left:.3rem">
            <span style="color:#646566">{{item.title}}</span>
          </div>
        </template>
        <template #right-icon>
          <van-uploader v-if="item.multiple == null || !item.multiple"
            accept="image/*"
            :max-count="item.max_count != null? item.max_count: 
              item.multiple != null && item.multiple? 9: 1"
            :after-read="(option) => fnUploadRequest(option, item.name)"
          >
            <van-image 
              width="40" height="40" fit="cover" :src="modal[item.name]" round />
          </van-uploader>
          <multiImageUpload 
            v-if="item.multiple"
            :name="item.name"
            :filesStr="modal[item.name]"
            v-on:files_updated="onMultiFileUpdated"
          ></multiImageUpload>
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-cell border center 
        v-if="item.type === 'file' && (item.compressed != null && !item.compressed)">
        <template #title>
          <span style="padding-left:.3rem;color:#646566">{{item.title}}</span>
        </template>
        <template #label>
          <van-uploader
            accept="image/*"
            :max-count="item.max_count != null? item.max_count: 
              item.multiple != null && item.multiple? 9: 1"
            :after-read="(option) => fnUploadRequest(option, item.name)"
          >
            <van-image v-if="modal[item.name] != ''"
            width="100" height="100" fit="cover" :src="modal[item.name]" />
          </van-uploader>
        </template>
      </van-cell>
      <van-cell border center is-link 
        v-if="item.type === 'select'">
        <template #right-icon>
          <van-field
            :label="item.title"
            :required="item.required && !item.disabled"
            readonly
            clickable
            :border="false"
            :name="item.name"
            :value="modal[item.name]"
            :placeholder="`请选择${item.title}`"
            :rules="item.rule"
            :disabled="item.disabled"
            @click="
              onSelectClick(item)
            "
            v-on:blur="emitChange()"
          >
          </van-field>
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-cell is-link :clickable="false" 
        v-if="item.type === 'date_select'" center size="small">
        <template #title>
          <van-row style="padding-left:.2rem;text-overflow:hidden">
            <van-col span="1">
              <div style="color:#ee0a24;width:.2rem;margin-left: -0.05rem;" v-if="item.required">*</div>
              <div style="color:#ee0a24" v-if="!item.required"></div>
            </van-col>
            <van-col span="23" style="text-overflow:hidden;white-space:nowrap">
              <div style="margin-left:-.01rem">
                <span style="color:#646566">{{item.title}}</span>
              </div>
            </van-col>
          </van-row>
        </template>
        <template #right-icon>
          <van-field
            type="text"
            :placeholder="`请选择${item.title}`"
            v-model="modal[item.name]"
            :border="false"
            :rules="item.rule"
            style="width:60%"
            @click="onDateSelectClick(item)"
          >
          </van-field>
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-cell border v-if="item.type === 'multi_select'"
        center size="small" is-link :clickable="false">
        <template #right-icon>
          <van-field 
            :border="false"
            type="text" :placeholder="`请选择${item.title}`" 
            :required="item.required" :label="item.title"
            v-model="modal[item.name]"
            @click="onMultiSelectClick(item)"
            />
          <van-icon name="arrow" />
        </template>
      </van-cell>
      
      <van-action-sheet v-model="showLocationSelection" v-if="item.type === 'area'">
        <van-cascader
          v-model="modal[item.name]"
          title="请选择所在地区"
          :options="regionData"
          @close="onAreaClose(item.name)"
          @change="onAreaChange(item.name)"
          @finish="(options) => onAreaConfirm(options, item.name)"
        />
      </van-action-sheet>
      <!-- <van-field
        v-if="item.type === 'address'"
        readonly
        clickable
        name="area"
        :value="modal[item.name]"
        label="区县选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="showArea = false"
        />
      </van-popup> -->
    </div>
    <van-popup v-model="selectionShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="currentSelectionData.values"
        @confirm="onSelectionConfirm"
        @cancel="selectionShow = false"
        v-on:blur="emitChange()"
      />
    </van-popup>
    <van-action-sheet v-model="showDateSelect" :overlay="true">
        <van-datetime-picker ref="dateSelector"
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          v-on:blur="emitChange()"
          @confirm="onDateConfirm"
          @cancel="showDateSelect = false"
        />
      </van-action-sheet>
    <van-action-sheet v-model="showMultiSelect" :overlay="true"
       title="选择语种" >

      <multiSelectTags
        :titles.sync="multiSelectValues"
        :selection="multiSelection"
        :selectStates="multiSelectStatus"
        v-on:selection-update="onMultiSelectUpdate"
      ></multiSelectTags>
      <van-row style="margin-bottom:.2rem">
        <van-col span="12">
          <van-button 
            native-type="button"
            type="default" round
            style="width:90%;margin-left:5%" 
            @click="onMultiSelectCancel"
            >取消</van-button>
        </van-col>
        <van-col span="12">
          <van-button 
            native-type="button"
            type="primary" round
            style="width:90%;margin-left:5%" 
            color="#395EE8"
            @click="onMultiSelectConfirm"
            >确认</van-button>
        </van-col>
      </van-row>
    </van-action-sheet>
    <div class="action_bg">
      <van-button
        block
        type="info"
        native-type="submit"
        style="z-index: 999"
        class="action_btn"
        color="#395EE8"
        round
        >{{submitBtnText}}</van-button
      >
    </div>
  </van-form>
</template>

<script>
import oss from "@/utils/oss";
import { Toast } from "vant";
import {mapGetters} from 'vuex'
import multiSelectTags from "./MultiSelectTags.vue"
import multiImageUpload from "./MultiImageUpload.vue"
export default {
  name: "fieldForm",
  components: {multiSelectTags, multiImageUpload},
  props: {
    modal: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fieldData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    inlineModal: {
      type: Object,
      default: () => {
        return {};
      },
    },
    submit: {
      type: Function,
    },
    submitBtnText: {
      type: String,
      default: "提交"
    }
  },
  data() {
    return {
      showArea: false,
      currentDate: new Date(),
      selectionShow: false,
      currentSelectionData: {},
      showDateSelect: false,
      currentDateSelectField: "",
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      showLocationSelection: false,
      showMultiSelect: false,
      multiSelectValues: [],
      multiSelectStatus: [],
      multiSelection: [],
      currentMultiSelections: [],
      multiSelectResult: "",
    };
  },
  created() {
    this.$store.dispatch('wode/getRegionData')
  },
  watch: {
    'fieldData': {
      handler(val) {
        val.forEach(field => {
          if(field.type == 'file' && field.multiple) {
            this.fileList[field.name] = []
            this.modal[field.name].split(',').forEach(ele => {
              if(ele != '') {
                this.fileList[field.name].push({
                  url: ele
                })
              }
            })
          }
        })
      }
    },
  },
  computed: {
    ...mapGetters('wode', {
      regionData: 'getRegionData'
    }),
    locationStrs() {
      let result = {}
      this.fieldData.map((field) => {
        result[field.name] = []
      })
      return result
    }
  },
  methods: {
    onConfirm() {},
    onSelectionConfirm(val) {
      this.modal[this.currentSelectionData.name] = val;
      this.selectionShow = false;
      this.emitChange('formUpdate', this.modal)
    },
    onDateConfirm(date) {
      this.modal[this.currentDateSelectField] = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
      this.showDateSelect = false;
      this.emitChange()
    },
    emitChange() {
      this.$emit("formUpdate", this.modal);
    },
    async fnUploadRequest(option, modal_key, multiple=false) {
      const _self = this;
      console.log(option);
      oss.ossUploadFile(option).then(
        (res) => {
          console.log(res);
          Toast("上传成功");
          if(!multiple) {
            _self.modal[modal_key] = res.fileUrl[0].split("?")[0];
          }
          this.emitChange();
          // _self.fileList[modal_key].push({url: res.fileUrl[0].split("?")[0], isImage: true})
        },
        (err) => {
          console.log("********", err);
          Toast("上传失败");
        }
      );
    },
    onMultiFileUpdated(data) {
      this.modal[data.name] = data.value
    },
    onUploadDelete(event, name) {
      this.fileList[name].splice(0, 1);
      this.modal[name] = "";
      this.emitChange();
    },
    onAreaConfirm({selectedOptions}, field) {
      const _self = this
      _self.modal[field] = selectedOptions.map((option) => option.text).join('/')

      this.modal.province_code = selectedOptions[0].value
      this.modal.city_code = selectedOptions[1].value
      this.modal.district_code = selectedOptions[2].value
      this.showLocationSelection = false
    },
    onAreaChange(field) {
      if(this.modal.province_code == '' || this.modal.city_code == '' || this.modal.district_code == '') {
        this.modal.province_code = ''
        this.modal.city_code = ''
        this.district_code = ''
        this.modal[field] = ''
      }
    },
    onAreaClose(field) {
      if(this.modal.province_code == '' || this.modal.city_code == '' || this.modal.district_code == '') {
        this.modal.province_code = ''
        this.modal.city_code = ''
        this.district_code = ''
        this.modal[field] = ''
      }
      this.showLocationSelection = false
    },
    onDateSelectClick(item) {
      if(item.customField == undefined || !item.customField) {
        this.showDateSelect = true;
        this.currentDateSelectField = item.name;
        if(item.maxDate != undefined) {
          this.maxDate = item.maxDate
        }
        if(item.minDate != undefined) {
          this.minDate = item.minDate
        }
      } else {
        this.$emit('date-select', item)
      }
    },
    onSelectClick(item) {
      if(!item.disabled) {
        this.currentSelectionData = item;
        this.selectionShow = true;
      }
    },
    onMultiSelectClick(item) {
      this.currentMultiSelections = item
      this.multiSelectValues = item.values
      this.multiSelectResult = this.modal[item.name]
      for (let i = 0; i < item.values.length; i++) {
        var value = item.values[i]
        if(this.multiSelectStatus[i] == null) {
          this.multiSelectStatus.push(false)
        }
        this.modal[item.name].split(',').forEach((ele) => {
          if(value.value === ele) {
            this.multiSelectStatus.splice(i, 1, true)
          }
        })
      }
      this.showMultiSelect = true
    },
    onMultiSelectUpdate(data) {
      var value = ""
      data.forEach((ele, idx) => {
        value += ele.value
        if(idx != data.length - 1) {
          value += ','
        }
      })
      this.multiSelectResult = value
    },
    onMultiSelectCancel() {
      this.multiSelection = []
      this.showMultiSelect = false
    },
    onMultiSelectConfirm() {
      this.multiSelection = []
      this.showMultiSelect = false
      this.modal[this.currentMultiSelections.name] = this.multiSelectResult
    }
  },
};
</script>

<style scoped>
.action_btn {
  position: fixed;
  bottom: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  padding-top: 10px;
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
  z-index: 666;
}
.content {
  padding: 16px 16px 160px;
}
::v-deep .van-field__control:read-only {
  cursor: default;
  text-align: right;
}
::v-deep .van-field__control {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 0;
    color: #323233;
    line-height: inherit;
    text-align: right;
    background-color: transparent;
    border: 0;
    resize: none;
}
::v-deep textarea {
  text-align: left !important;
}
.van-field__error-message {
    color: #ee0a24;
    font-size: 12px;
    text-align: right;
}
::v-deep .van-field__label span {
  white-space: nowrap;
}
</style>