<template>
  <div>
    <van-uploader v-model="fileList" 
      :after-read="(option) => fnUploadRequest(option)"/>
  </div>
</template>

<script>
import oss from "@/utils/oss";
import { Toast } from "vant";
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    filesStr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: [],
    }
  },
  mounted() {
    this.filesStr.split(',').forEach(ele => {
      if(ele != '') {
        this.fileList.push({url: ele})
      }
    })
  },
  watch: {
    'fileList': {
      handler(val) {
        let result = []
        val.forEach(ele => {
          result.push(ele.url)
        })
        this.$emit('files_updated', {name: this.name, value: result.join()})
      },
      deep: true
    }
  },
  methods: {
    async fnUploadRequest(option) {
      const _self = this;
      console.log(option);
      oss.ossUploadFile(option).then(
        (res) => {
          console.log(res);
          Toast("上传成功");
          
          _self.fileList.splice(_self.fileList.length - 1, 1,  
            {url: res.fileUrl[0].split('?')[0]})
          console.log(this.fileList);
        },
        (err) => {
          console.log("********", err);
          Toast("上传失败");
        }
      );
    },
  }
}
</script>

<style>

</style>